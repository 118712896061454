import "es6-promise/auto";
import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { config, CognitoIdentityCredentials } from "aws-sdk";

function Detector() {
  const { sessionId } = useParams();

  const onAnalysisCompleteDetection = () => {
    // Handle the analysis completion result
    window.parent.postMessage("done", "*");
  };

  const onAnalysisComplete = () => {
    // Handle the analysis completion result
    window.parent.postMessage("done", "*");
  };

  const onUserCancel = () => {
    // Handle the analysis cancel result
    window.parent.postMessage("Você cancelou a prova de vida.", "*");
  };

  const onError = (result) => {
    // Handle the analysis completion result
    switch (result?.state) {
      case "TIMEOUT":
        window.parent.postMessage("Tempo esgotado, tente novamente!", "*");
        break;
      case "MULTIPLE_FACES_ERROR":
        window.parent.postMessage(
          "Evite se mover durante a contagem e certifique-se de que há apenas um rosto na câmera.",
          "*"
        );
        break;
      case "RUNTIME_ERROR":
        window.parent.postMessage(
          "Erro de execução, verifique as permissões e tente novamente!",
          "*"
        );
        break;
      case "FRESHNESS_TIMEOUT":
        window.parent.postMessage(
          "Falha ao identificar a veracidade da fonte de vídeo. Tente novamente!",
          "*"
        );
        break;
      case "SERVER_ERROR":
        window.parent.postMessage(
          "Problemas de conexão. Verifique sua internet e tente novamente!",
          "*"
        );
        break;
      case "CAMERA_FRAMERATE_ERROR":
        window.parent.postMessage(
          "Problemas de leitura da câmera. Reinicie o aparelho e tente novamente!",
          "*"
        );
        break;
      case "CAMERA_ACCESS_ERROR":
        window.parent.postMessage(
          "Acesso negado à câmera. Verifique as permissões e tente novamente!",
          "*"
        );
        break;
      case "FACE_DISTANCE_ERROR":
        window.parent.postMessage(
          "Rosto muito distante, tente novamente!",
          "*"
        );
        break;
      case "MOBILE_LANDSCAPE_ERROR":
        window.parent.postMessage(
          "Para um aproveitamento total da câmera, mantenha o aparelho em pé. Aparelho na horizontal não suportado!",
          "*"
        );
        break;
      case "MULTIPLE_FACES_ERROR":
        window.parent.postMessage(
          "Múltiplos rostos detectados. Certifique-se de que há apenas um rosto na câmera.",
          "*"
        );
        break;
      default:
        window.parent.postMessage("Ocorreu um erro, tente novamente!", "*");
        break;
    }
  };

  const credentialProvider = async () => {
    // Fetch the credentials
    config.update({ region: "us-east-1" });

    config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    });

    await config.credentials.getPromise();

    return config.credentials;
  };

  const i18n = {
    timeoutHeaderText: "Tempo esgotado",
    timeoutMessageText:
      "O rosto não se ajustou ao oval dentro do tempo limite. Tente novamente e preencha completamente o oval com o rosto.",
    faceDistanceHeaderText: "Movimento para frente detectado",
    faceDistanceMessageText: "Evite se aproximar ao conectar.",
    multipleFacesHeaderText: "Múltiplos rostos detectados",
    multipleFacesMessageText:
      "Certifique-se de que apenas um rosto esteja presente em frente à câmera ao conectar.",
    clientHeaderText: "Erro do cliente",
    clientMessageText: "Verificação falhou devido a um problema do cliente",
    serverHeaderText: "Problema de conexão ou sessão inválida",
    serverMessageText:
      "Não é possível completar a verificação devido a um problema de conexão ou sessão inválida",
    landscapeHeaderText: "Orientação paisagem não suportada",
    landscapeMessageText:
      "Gire seu dispositivo para orientação retrato (vertical).",
    portraitMessageText:
      "Certifique-se de que seu dispositivo permaneça em orientação retrato (vertical) durante a duração da verificação.",
    instructionsHeaderHeadingText: "Verificação de pessoa real",
    instructionsHeaderBodyText:
      "Você passará por um processo de verificação facial para provar que é uma pessoa real.",
    instructionsBeginCheckText: "Iniciar",
    photosensitivyWarningHeadingText: "Aviso de fotosensibilidade",
    photosensitivyWarningBodyText:
      "Esta verificação mostra luzes coloridas. Tenha cuidado se for fotosensível.",
    photosensitivyWarningInfoText:
      "Uma pequena porcentagem de indivíduos pode ter crises epilépticas quando expostos a luzes coloridas. Tenha cuidado se você, ou alguém em sua família, tiver uma condição epiléptica.",
    instructionListHeadingText:
      "Siga as instruções para completar a verificação:",
    goodFitCaptionText: "Encaixe correto",
    goodFitAltText:
      "Ilustração de um rosto de pessoa, encaixando perfeitamente dentro de um oval.",
    tooFarCaptionText: "Muito distante, aproxime-se",
    tooFarAltText:
      "Ilustração de um rosto de pessoa dentro de um oval; há um espaço entre o perímetro do rosto e os limites do oval.",
    instructionListStepOneText:
      "Quando aparecer uma forma oval na tela, preencha a forma oval com o seu rosto em 7 segundos.",
    instructionListStepTwoText:
      "Aumente o brilho da sua tela no máximo e/ou vá para um local bem iluminado.",
    instructionListStepThreeText: "Não use óculos ou máscara",
    instructionListStepFourText:
      "Mova-se para um local bem iluminado que não esteja exposto à luz solar direta.",
    cameraMinSpecificationsHeadingText:
      "Câmera não atende às especificações mínimas",
    cameraMinSpecificationsMessageText:
      "A câmera deve suportar pelo menos resolução de 320*240 e 15 quadros por segundo.",
    cameraNotFoundHeadingText: "Câmera não acessível.",
    cameraNotFoundMessageText:
      "Verifique se a câmera está conectada e as permissões da câmera estão ativadas nas configurações antes de tentar novamente.",
    retryCameraPermissionsText: "Tentar novamente",
    cancelLivenessCheckText: "Cancelar verificação de vivacidade",
    recordingIndicatorText: "Grav",
    hintMoveFaceFrontOfCameraText: "Mova o rosto em frente à câmera",
    hintTooManyFacesText:
      "Certifique-se de que apenas um rosto esteja em frente à câmera",
    hintFaceDetectedText: "Rosto detectado",
    hintCanNotIdentifyText: "Mova o rosto em frente à câmera",
    hintTooCloseText: "Afaste-se",
    hintTooFarText: "Aproxime-se",
    hintHoldFacePositionCountdownText:
      "Mantenha a posição do rosto durante a contagem regressiva",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintIlluminationTooBrightText: "Mova-se para uma área mais escura",
    hintIlluminationTooDarkText: "Mova-se para uma área mais clara",
    hintIlluminationNormalText: "Condições de iluminação normais",
    hintHoldFaceForFreshnessText: "Fique imóvel",
    tryAgainText: "Tente novamente",
    TryAgainText: "Tente novamente",
    tryAgain: "Tente novamente",
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const buttonElement = document.querySelector(
        ".amplify-flex.amplify-liveness-toast__message .amplify-button.amplify-field-group__control.amplify-button--primary"
      );
      if (buttonElement) {
        if (buttonElement.textContent === "Try again") {
          buttonElement.textContent = "Tente novamente";
        }
      }
    }, 100);
    // Limpando o interval quando o componente é desmontado
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const rootDiv = document.getElementById("root");
      if (rootDiv) {
        const h = rootDiv.offsetHeight;
        window.parent.postMessage(`height:${h}`, "*");
      }
    }, 100);
    // Limpando o interval quando o componente é desmontado
    return () => clearInterval(intervalId);
  });

  return (
    <FaceLivenessDetector
      sessionId={sessionId}
      region="us-east-1"
      onAnalysisCompleteDetection={onAnalysisCompleteDetection}
      onError={onError}
      onAnalysisComplete={onAnalysisComplete}
      onUserCancel={onUserCancel}
      disableInstructionScreen={true}
      displayText={i18n}
      config={{ credentialProvider }}
    />
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="rek/:sessionId" element={<Detector />} />
      </Routes>
    </Router>
  );
}

export default App;
